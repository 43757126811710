import React from 'react';
import TimelineVJ from './TimelineVJ'

export default function TimelineMain() {
  return (
    <div>
    <div>
      <TimelineVJ />  
    </div>
    </div>
  )
}
