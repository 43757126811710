import React, {useState} from 'react';
import axios from "axios";
import { Button } from 'react-bootstrap';

const config = require('../../config.json');

export default function ContactContent() {
    const [queryDetails, setQueryDetails]=useState({name:'', email:'', phoneNo:'', desc:''});
    const [transactionMsg, setTransactionMsg]=useState('');
    const [buttonClicked, setButtonClicked]=useState(false);

    const handleContactUsQuery=async(event)=>{
        event.preventDefault();
        setTransactionMsg('');
        setButtonClicked(true);
        try{
            //console.log("Query : ", queryDetails);
            const {name, email, phoneNo, desc} = queryDetails;
            if(name===""||email===""||desc===""||phoneNo===""){
              setTransactionMsg("Kindly fill all the details to share your Query/Feedback");
            }else{
              const inputParams={
                name : name,
                phoneNo : phoneNo,
                emailID : email,
                desc : desc
              };
              //console.log("Params : ", inputParams);
            await axios.post(`${config.api.invokeURL}/contact-us/query`, inputParams,{
              headers: {
              //Authorization: access_token,
              'x-api-key':config.api.key
              }})
              .then((response)=>{
                //console.log("Response : ", response);
                if (response.status===200){
                  setTransactionMsg("Thank you for your Query/feedback! our team will get back to you soon!");
                  setQueryDetails({...queryDetails, name:'', email:'', desc:'', phoneNo:''})
                  setButtonClicked(false);
                }
              })
              .catch((error)=>{
                //console.log("Error : ", error);
                setTransactionMsg("Error Occured : "+error.message);
                setButtonClicked(false);
              });
            }
            setButtonClicked(false);
        }catch(error){
            //console.log("error :", error);
            setTransactionMsg("Error : " + error.message )
        }
    }

  return (
    <div className="container-fluid py-2 mb-3">
    <div className="container">
        <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: "600px"}}>
            <h5 className="text-primary">Get In Touch</h5>
            <h1 className="mb-3 text-dark-main">Contact for any query</h1>
            {/*<p className="mb-2">The contact form is currently inactive. Get a functional and working contact form with Ajax & PHP in a few minutes. Just copy and paste the files, add a little code and you're done. <a href="https://htmlcodex.com/contact-form">Download Now</a>.</p>*/}
        </div>
        <div className="contact-detail position-relative p-2">
            <div className="row g-5 mb-5 justify-content-center">
                <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".3s">
                    <div className="d-flex bg-light p-2 rounded">
                        <div className="flex-shrink-0 btn-square Ylight-bg rounded-circle" style={{width: "94px", height: "94px"}}>
                            <i className="fas fa-map-marker-alt text-dark-main" style={{fontSize:"30px"}}></i>
                        </div>
                        <div className="ms-3">
                            <h4 className="text-primary">Address</h4>
                            <a href="https://goo.gl/maps/Zd4BCynmTb98ivUJ6" target="_blank" className="h6">4-A, Raj Niwas Marg, Delhi – 110054</a>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".5s">
                    <div className="d-flex bg-light p-2 rounded">
                        <div className="flex-shrink-0 btn-square Ylight-bg rounded-circle" style={{width: "94px", height: "94px"}}>
                            <i className="fa fa-phone text-dark-main" style={{fontSize:"30px"}}></i>
                        </div>
                        <div className="ms-3">
                            <h4 className="text-primary">Call Us</h4>
                            <a className="h6" href="tel:+919971186877" target="_blank">(+91) 99711 86877</a>
                            <br /> <a className="h6" href="tel:+919971186877" target="_blank">{"  "}   </a>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".7s">
                    <div className="d-flex bg-light p-2 rounded">
                        <div className="flex-shrink-0 btn-square Ylight-bg rounded-circle" style={{width: "94px", height: "94px"}}>
                            <i className="fa fa-envelope text-dark-main" style={{fontSize:"30px"}}></i>

                        </div>
                        <div className="ms-3">
                            <h4 className="text-primary">Email Us</h4>
                            <a className="h6" href="mailto:vidyajyotic@gmail.com" target="_blank">vidyajyotic@gmail.com</a>
                            <br /><a className="h6" href="mailto:principalvj@gmail.com" target="_blank">principalvj@gmail.com</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row g-5">
                <div className="col-lg-6 wow fadeIn" data-wow-delay=".3s">
                    <div className="p-1 h-100 rounded Ylight-bg">
                        <iframe className="rounded w-100 h-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1696.467026695403!2d77.22035555589248!3d28.67194436438119!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfda0e1b6e4eb%3A0x32051ba490c17c76!2sVidyajyoti%20College%20of%20Theology!5e0!3m2!1sen!2sin!4v1698725643824!5m2!1sen!2sin" style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
                <div className="col-lg-6 wow fadeIn" data-wow-delay=".5s">
                    <div className="p-1 rounded Ylight-bg">
                        <div className="mb-4">
                            <input type="text" className="form-control border-0 py-3" placeholder="Your Name" value={queryDetails.name} onChange={event=>setQueryDetails({...queryDetails, name:event.target.value})}/>
                        </div>
                        <div className="mb-4">
                            <input type="email" className="form-control border-0 py-3" placeholder="Your Email" value={queryDetails.email} onChange={event=>setQueryDetails({...queryDetails, email:event.target.value})}/>
                        </div>
                        <div className="mb-4">
                            <input type="text" className="form-control border-0 py-3" placeholder="Phone No" value={queryDetails.phoneNo} onChange={event=>setQueryDetails({...queryDetails, phoneNo:event.target.value})}/>
                        </div>
                        <div className="mb-4">
                            <textarea className="w-100 form-control border-0 py-3" rows="6" cols="10" placeholder="Message" value={queryDetails.desc} onChange={event=>setQueryDetails({...queryDetails, desc:event.target.value})}></textarea>
                        </div>
                        <div className="mb-4">
                            {transactionMsg}
                            </div>
                        <div className="text-center p-2">
                            <Button className="btn text-white py-2 px-2"  variant="primary" onClick={event=>handleContactUsQuery(event)}>Send Message</Button>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    </div> 
</div>
  )
}
