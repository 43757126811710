import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { Col, Row } from 'react-bootstrap';
import Preloader from '../../components/Preloader/Preloader';
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import '../../App.css';
const config = require('../../config.json');

export default function PhotoGalleryHome(props) {
  const [galleryImages, setGalleryImages]=useState([]);
	const [collapsed, setCollapsed] = useState(true);
	const [selectedProject, setSelectedProject]= useState([]);

useEffect(() => { 
  fetchGalleryImages();
}, []);

const fetchGalleryImages =async()=>{
try{
    const fileName='images/gallery/';
    const bucketName="images.vidyajyoticollege.in";
  await axios.get(`${config.api.invokeURL}/gallery?fileName=${fileName}&bucketName=${bucketName}`,{
    headers: {
     // Authorization: access_token,
      'x-api-key': config.api.key
    }}, 
).then((response)=>{
   // console.log(response.data);
   if(props.task==="Home"){
    const tempRes=response.data;
    tempRes.sort((a,b)=>new Date(b.projectDate)-new Date(a.projectDate));
   const slicedArray = tempRes.slice(0, 6);
    setGalleryImages(slicedArray);
   }else{
    setGalleryImages(response.data);
   }
   }).catch((error)=>{
    console.log("Error:",error.message);
   })
}catch(error){
console.log(error.message);
}
}

const assignProject =(event, item)=>{
	event.preventDefault();
	setSelectedProject(item.fileName);
	toggle();
}

    const toggle = () => {
        setCollapsed(!collapsed);
    }

    return (
      <div className=''>
      {/*<Helmet>
        <title>Gallery</title>
        <meta name="description" content="Gallery - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
        <meta name="keywords" content="Gallery - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
        <link rel="canonical" href="/gallery" />md={4} lg={4} 
      </Helmet>*/}

        {/*} <Preloader show={loaded ? false : true} /> */}
          <Modal  fullscreen isOpen={!collapsed} style={{top:'0%'}}>
          <ModalHeader
          close={<button className='btn bg-white' onClick={toggle}>X</button>}
          toggle={toggle}/>
          <ModalBody>
          <Carousel stopOnHover={true} dynamicHeight={true} infiniteLoop={true} autoPlay={true} showThumbs={false} showArrows={true} showIndicators={false} showStatus={false}>
          {selectedProject.map((img1, index)=>(
          <img key={index} src={`${config.s3.ImageinvokeURL}${img1}`} alt="Avatar" style={{width:'70%'}} />
          ))} 
          </Carousel>
          </ModalBody>
        </Modal>
        <div className="section-title">
       {/*} <h1 className='text-center text-primary pt-4' style={{fontWeight:'900'}}>Gallery</h1>
          <br/>*/}
        <Row >
        <div className="card-group" >
        {galleryImages.map((item, index)=>
        <Col className='p-2' sm={6} md={6} key={index}>
        <div className='gal-bg-view card p-2 d-flex flex-column h-100'  style={{ justifyContent:'center', textAlign:'center', color:'white', backgroundColor:"#03AED2"}}>
            <div  key={index}  style={{paddingLeft:'0px', paddingRight:'0px'}} >
                        <div className='proj' onClick={(event)=>{assignProject(event, item)}}>
                      <div className="icon image-popup d-flex align-items-center text-center" >
                      <span className='icon-expand text-center position-absolute top-50 start-50 translate-middle'>
                    <i className="fa fa-expand"></i>
                    </span> 
        </div>
      

        <Carousel  infiniteLoop={true} autoPlay={true} showThumbs={false} showArrows={false} showIndicators={false} showStatus={false}>
            {(item.fileName).map((img1,index)=>
            <div  key={index}>
                          <img key={index} src={encodeURI(`${config.s3.ImageinvokeURL}${img1}`)} alt='projects' style={{width: "100%", borderRadius:'30px'}}/>
              </div>  )}
              </Carousel>
             
                <h3 className="heading" style={{color: 'white', fontWeight:'400', fontSize:"16px", paddingTop:"5px"}}> {item.projectName}</h3>
                <div style={{fontSize:"12px"}}>{item.projectDate} </div>
          
      
        </div>
       </div>
</div>
 </Col>)}
 </div>
</Row>
     </div> 
     </div>
    )
}
