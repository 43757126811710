import React, { useState, useEffect } from 'react';
import './timelinemain.css';
//import { vjcTopStoriesList } from '../../ProData';
import axios from 'axios';
const config = require('../../config.json');



export default function TimelineVJ () {
    const [insights, setInsights] = useState([]);
    const [loaded, setLoaded] = useState(false);
    //const [imgsrc, setImgsrc] = useState();

    useEffect(() => {
        fetchActiveInfo();
    }, [])

    const generateDate = async () => {
        const current_datetime = new Date();
        const date_year = (current_datetime.getFullYear()).toString();
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        var date_date = (current_datetime.getDate().toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        var date_hours = (current_datetime.getHours().toString());
        var hours_value="";
        date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
        var date_minutes = (current_datetime.getMinutes().toString());
        var minutes_value="";
        date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
        var todayDate="";
        const tDate = todayDate.concat(date_year,"-",month_value,"-", date_value);
        //setUserId(accountNo);
        return tDate;
        //console.log("acc : ", accountNo);
    } 


    const fetchActiveInfo =async()=>{
        try{
           setLoaded(false);
            const startDate=await generateDate();
            const clientID="VJCollege";
            const searchOption="Top Story";
        await axios.get(`${config.api.invokeURL}/dashboard/infodesk?clientID=${clientID}&startDate=${startDate}&searchOption=${searchOption}`,{
            headers: {
             // Authorization: access_token,
              'x-api-key': config.api.key
            }}, 
        ).then((response)=>{
            if(response.data.length>0){
                const test=response.data;
                //const result=test.sort((a,b) => (a.updatedOn > b.updatedOn) ? 1 : ((b.updatedOn > a.updatedOn) ? -1 : 0))
                const result=test.sort((a,b) => new Date(b.startDate) - new Date(a.startDate) ); // b - a for reverse sort
                //console.log(result);
                setInsights(result);
                setLoaded(true);
            }
        }).catch((error)=>{
            setLoaded(true);
        })
    }catch(error){
        setLoaded(true);
    }
    }

    return (
        <div style={{height:"1200px"}}>
      <div className="timelineCSS" >
    <ul>
    {loaded && insights.length>0 && insights.map((story, index)=>
    <li style={{"--accent-color":story.topStory.timelineColor}} key={index}>
        <div className="date">{story.topStory.timelineHeader}</div>
        <div className="title">{story.topStory.timelineTitle}</div>
        {story.topStory.timelineImage && <div className="title"><img src={`${config.s3.ImageinvokeURL}${"images/topstories/"}${story.topStory.timelineImage}`} alt={story.topStory.timelineTitle} width="340px" height="340px"/></div>}
        <div className="descr">{story.topStory.timelineDescription}</div>
    </li>)}
</ul>

      </div>
      </div>
    )
  
}
