import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import NewFaceView from './NewFaceView';
import CaroselMultiView from './CaroselMultiView';
import {Container, Row, Col, Button  } from "reactstrap";
import {Helmet} from "react-helmet-async";
import VidyaJyotiBanner from '../VidyaJyotiBanner';
//import '../css/style.css';
import PhotoGallery from '../../pages/gallery/PhotoGallery';
import TimelineVJ from '../../pages/TimeLineVJ/TimelineVJ';
//import AboutUsHomepageView from '../about/AboutUsHomepageView';
import TheologicalInsights from './TheologicalInsights';
import Services from '../publications/Services';
import ContactContent from '../contact/ContactContent';
import FieldWorkContent from '../fieldwork/FieldWorkContent';
import { Link } from 'react-router-dom'
//import UpcomingEvents from './upcomingEvents/UpcomingEvents';
import UpcomingMain from './upcomingEvents/UpcomingMain';
import PhotoGalleryHome from '../gallery/PhotoGalleryHome';

const config = require('../../config.json');

export default function HomePage() {

  return (
    <div className='home-sec'>
    <Helmet>
      <title>Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/" />
    </Helmet>

    <div className="App" >
      <Row className='caroselrow pb-3' style={{marginLeft:"0px", marginRight:"0px"}}>
        <Col  md={9} >
        <CaroselMultiView />
        <NewFaceView/>
        </Col>
        <Col  md={3} >
         <TheologicalInsights />
        </Col>
      </Row>
      <div style={{marginLeft:"10px", marginRight:"0px"}}>
      <UpcomingMain />
      <VidyaJyotiBanner />
      </div>
        <div >
        <Row >
      <Col xl={8}  className="d-flex flex-column">
      <h1 className='text-dark-main'>GALLERY</h1>
      <div className="p-2 flex-grow-1 overflow-auto custom-scroll">
      <PhotoGalleryHome task={"Home"}/>
      </div>
      </Col>
    <Col xl={4} style={{height:"1300px"}}>
      <h1 className='text-dark-main'>VJ TOP STORIES</h1>
      <div className='card' style={{marginLeft:"10px", marginRight:"20px"}}>
        <div style={{overflow:"scroll"}}>
      <TimelineVJ />  
      </div>
      
      <Button variant="primary" type="submit" ><Link to="/about/vjtopstories"> <a href="#" className='text-white'>View More...</a></Link></Button>
      </div>
      </Col>
      </Row>      
      </div>

      <FieldWorkContent />
      <Services />

      <ContactContent />
    </div>
    </div>
  )
}
