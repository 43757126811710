import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { Col, Row } from 'react-bootstrap';
import Preloader from '../../components/Preloader/Preloader';
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import '../../App.css';
const config = require('../../config.json');

export default function PhotoGallery(props) {
  const [galleryImages, setGalleryImages]=useState([]);
	const [collapsed, setCollapsed] = useState(true);
	const [selectedProject, setSelectedProject]= useState([]);

useEffect(() => { 
  fetchGalleryImages();
}, []);

const fetchGalleryImages =async()=>{
try{
    const fileName='images/gallery/';
    const bucketName="images.vidyajyoticollege.in";
  await axios.get(`${config.api.invokeURL}/gallery?fileName=${fileName}&bucketName=${bucketName}`,{
    headers: {
     // Authorization: access_token,
      'x-api-key': config.api.key
    }}, 
).then((response)=>{
   // console.log(response.data);
   if(props.task==="Home"){
    const tempRes=response.data;
    tempRes.sort((a,b)=>new Date(b.projectDate)-new Date(a.projectDate));
   const slicedArray = tempRes.slice(0, 9);
    setGalleryImages(slicedArray);
   }else{
    setGalleryImages(response.data);
   }
   }).catch((error)=>{
    console.log("Error:",error.message);
   })
}catch(error){
console.log(error.message);
}
}

const assignProject =(event, item)=>{
	event.preventDefault();
	setSelectedProject(item.fileName);
	toggle();
}

    const toggle = () => {
        setCollapsed(!collapsed);
    }

    return (
      <div className='container'>
      {/*<Helmet>
        <title>Gallery</title>
        <meta name="description" content="Gallery - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
        <meta name="keywords" content="Gallery - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
        <link rel="canonical" href="/gallery" />md={4} lg={4} 
      </Helmet>*/}

        {/*} <Preloader show={loaded ? false : true} /> */}
          <Modal  fullscreen isOpen={!collapsed} style={{top:'0%'}}>
          <ModalHeader
          close={<button className='btn bg-white' onClick={toggle}>X</button>}
          toggle={toggle}/>
          <ModalBody>
          <Carousel stopOnHover={true} dynamicHeight={true} infiniteLoop={true} autoPlay={true} showThumbs={false} showArrows={true} showIndicators={false} showStatus={false}>
          {selectedProject.map((img1, index)=>(
          <img key={index} src={`${config.s3.ImageinvokeURL}${img1}`} alt="Avatar" style={{width:'70%'}} />
          ))} 
          </Carousel>
          </ModalBody>
        </Modal>
        <div className="section-title">
        <h1 className='text-center text-primary pt-4' style={{fontWeight:'900'}}>Gallery</h1>
          <br/>
        <Row>
        <div class="card-group">
        {galleryImages.map((item, index)=>
        <Col className='p-2' sm={6} md={4} key={index}>
        <div className='gal-bg-view card p-2'  style={{ justifyContent:'center', textAlign:'center', color:'white', backgroundColor:"#03AED2"}}>
            <div  key={index}  style={{paddingLeft:'0px', paddingRight:'0px'}} >
                        <div className='proj' onClick={(event)=>{assignProject(event, item)}}>
        
                      <div className="icon image-popup d-flex justify-content-center align-items-center" >
        <span className='icon-expand'>
                    <i className="fa fa-expand">
                    </i>
                    </span> 
        </div>
                        <Carousel infiniteLoop={true} autoPlay={true} showThumbs={false} showArrows={false} showIndicators={false} showStatus={false}>
            {(item.fileName).map((img1,index)=>
            <div key={index}>
                          <img key={index} src={encodeURI(`${config.s3.ImageinvokeURL}${img1}`)} alt='projects' style={{width: "400px", height: "300px", float:'midle', borderRadius:'30px'}}/>
              </div>  )}
              </Carousel>
              <h3 className="heading" style={{color: 'white', fontWeight:'500'}}> {item.projectName}</h3>
                <div style={{fontSize:"18px"}}>{item.projectDate} </div>
        </div>
       </div>
</div>
 </Col>)}
 </div>
</Row>
     </div> 
     </div>
    )
}
